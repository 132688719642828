import { sanitize } from "isomorphic-dompurify";
import Image from "next/image";
import { motion } from "framer-motion";

import {
  LayoutType,
  LinkType,
  ImageType,
  isAvailable,
} from "../../utils/baseTypes";
import { Section } from "../Global/Section";
import { Button } from "../Global/Button";
import { unlinkWiki } from "../../utils/unlinkWiki";
import useWindowSize from "../../hooks/useWindowSize";

export type PromoBannerType = {
  acf_fc_layout: "promo_banner";
  promo_banner: {
    headline: string;
    subline: string;
    content: string;
    image: ImageType | false;
    link: LinkType;
  };
  layout: LayoutType;
};

interface PromoBannerProps {
  data: PromoBannerType;
  lastIsPromo: boolean;
}

const transition_ = {
  ease: [0.455, 0.03, 0.515, 0.955],
  duration: 0.85,
};

const variants = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: transition_,
  },
};

const stagger_variant = {
  visible: {
    transition: {
      delay: 0.5,
      staggerChildren: 0.3,
    },
  },
};

const PromoBanner = ({ data, lastIsPromo }: PromoBannerProps) => {
  const { background, width, abstand, bild_typ, hsize_tag } = data.layout;
  const image = isAvailable<ImageType>(data.promo_banner.image)
    ? data.promo_banner.image
    : null;

  const link = isAvailable<LinkType>(data.promo_banner.link)
    ? data.promo_banner.link
    : null;

  const { width: windowWidth } = useWindowSize();
  const isTouch = windowWidth < 824;

  let textOnly = false;

  let LeftSideJSX;
  const Headline = (
    <>
      {hsize_tag === "normal" ? (
        <span className="h3 mb-1">
          <strong
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data.promo_banner.headline)),
            }}
          ></strong>
        </span>
      ) : (
        <h2 className="mb-1">
          <strong
            dangerouslySetInnerHTML={{
              __html: sanitize(unlinkWiki(data.promo_banner.headline)),
            }}
          ></strong>
        </h2>
      )}

      {data.promo_banner.subline !== "" && (
        <span
          className="subline"
          dangerouslySetInnerHTML={{
            __html: sanitize(unlinkWiki(data.promo_banner.subline)),
          }}
        ></span>
      )}
    </>
  );

  if (image) {
    let imageJSX;
    if (image.mime_type === "image/svg+xml") {
      imageJSX = (
        <Image
          alt={image.alt}
          src={image.url!}
          width={0}
          height={0}
          style={{ width: "100%", height: "auto" }}
          sizes="(max-width: 468px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 8vw, 7vw"
        />
      );
    } else {
      if (bild_typ === "round") {
        imageJSX = (
          <figure className="image">
            <Image
              src={image.url!}
              alt={image.alt}
              data-title={image.title}
              className="is-rounded"
              width={image.width}
              height={image.height}
              sizes="(max-width: 468px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 8vw, 7vw"
            />
          </figure>
        );
      } else {
        imageJSX = (
          <Image
            src={image.url!}
            alt={image.alt}
            data-title={image.title}
            width={image.width}
            height={image.height}
            sizes="(max-width: 468px) 50vw, (max-width: 1023px) 25vw, (max-width: 1440px) 8vw, 7vw"
          />
        );
      }
    }

    LeftSideJSX = (
      <div className="columns is-multiline is-vcentered promo-image-headline">
        <div className="column is-12 is-4-widescreen">
          <div className="promo-image-wrapper">{imageJSX}</div>
        </div>
        <div className="column is-12 is-8-widescreen headline-wrapper">
          {Headline}
        </div>
      </div>
    );
  } else {
    LeftSideJSX = (
      <div className="has-text-right-desktop headline-wrapper">{Headline}</div>
    );
    textOnly = true;
  }
  return (
    <Section
      className={`promo-banner ${
        width !== "normal" ? background : "has-white-bg"
      } ${abstand === "default" ? "" : abstand} ${
        isTouch && lastIsPromo ? "pb-0" : ""
      }`}
      disableColumns
      containerClassName={`${isTouch && lastIsPromo ? "pb-0" : ""}`}
    >
      <motion.div
        variants={stagger_variant}
        initial="hidden"
        whileInView={"visible"}
        viewport={{ once: true, amount: 0.3 }}
        className={`columns m-0 is-vcentered is-centered is-multiline promobanner-columns ${
          width === "normal" ? background : ""
        }`}
      >
        <motion.div
          variants={variants}
          className={`column is-12 is-6-desktop promo-banner-left ${
            textOnly ? "text-only" : ""
          }`}
        >
          {LeftSideJSX}
        </motion.div>
        <motion.div
          variants={variants}
          className={`column is-12 is-6-desktop promo-banner-right`}
        >
          <div
            className="promo-banner-content"
            dangerouslySetInnerHTML={{
              __html: sanitize(data.promo_banner.content, {
                ADD_ATTR: ["target"],
              }),
            }}
          ></div>

          {link && (
            <div className="button-wrapper mt-4">
              <Button
                type="primary"
                title={link.title}
                target={link.target}
                href={link.url}
              ></Button>
            </div>
          )}
        </motion.div>
      </motion.div>
    </Section>
  );
};

export default PromoBanner;
